<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <div class="w-100 mb-1 text-center">
        <p
          class="mb-1"
          v-html="$t('DOWNLOAD_YOUR_APP_HERE')"
        />

        <b-link
          target="_blank"
          :href="appUrl.android"
        >
          <b-img
            fluid
            :src="require('@/assets/images/pages/google_badge.svg')"
            width="150px"
            height="60px"
            class="center-img"
            alt="landing page"
          />
        </b-link>

        <b-link
          target="_blank"
          :href="appUrl.ios"
        >
          <b-img
            fluid
            :src="require('@/assets/images/pages/ios_badge.svg')"
            width="136px"
            height="60px"
            alt="landing page"
          />
        </b-link>
      </div>
      <!-- Header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            :icon="mapToContentTypeIcon(content.contentTypeId).icon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ content.name }}
            <small class="text-muted">
              <b-badge
                class="ml-1 small"
                :variant="`${mapFrequencyToVariant(content.contentFrequency.name)}`"
              >
                {{ content.contentType.name }}
              </b-badge>
              <b-badge
                class="ml-1 small"
                :variant="`${mapFrequencyToVariant(content.contentFrequency.name)}`"
              >
                {{ content.contentFrequency.name }}
              </b-badge>
              <b-badge
                class="ml-1 small"
                variant="light-info"
              >
                {{ content.contentStatus.name }}
              </b-badge>
            </small>
            <feather-icon
              v-if="canEditContent"
              icon="EditIcon"
              size="16"
              class="text-muted cursor-pointer ml-1"
              @click="$router.push({ name: 'content-configuration-id', params: { id: content.id }})"
            />
            <b-button
              v-show="content.contentStatusId === 1"
              variant="primary"
              class="shadow ml-1"
              @click="validateContentPublishReadiness(content)"
            >
              <span>{{ $t('PUBLISH_CONTENT') }} </span>
            </b-button>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="content.description" />
          </b-card-text>
        </div>
      </div>
      <!-- Start Date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.start) }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- End Date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(content.end) }}
          </h6>
        </b-media-body>
      </b-media>
      <div>
        <b-card-text class="mt-2">
          <h5>{{ $t('AFTER_PUBLISHING_THE_CONTENT') }}</h5>
          <p class="ml-3 mt-1">
            {{ $t('MESSAGE.AFTER_PUBLISH') }}
          </p>
          <p class="ml-3">

            <ul>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="ActivityIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_TASK') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="TargetIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_GOAL') }}
                  </h6>
                </b-media-body>
              </b-media>
              <b-media
                no-body
              >
                <b-media-aside class="mr-1">
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                  >
                    <feather-icon
                      icon="GiftIcon"
                      size="18"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mt-0 pt-50">
                    {{ $t('ADD_CHARITY') }}
                  </h6>
                </b-media-body>
              </b-media>
            </ul>
          </p>
        </b-card-text>
      </div>
    </b-card-body>
    <hr class="invoice-spacing">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-task-view
          :content-id="Number(content.id)"
          :all-content-tasks="allContentTasks"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
    <hr class="invoice-spacing">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-set-view
          :content-id="Number(content.id)"
          :settimes="allSettimes"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
    <hr class="invoice-spacing">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <ContentSetLineChart />
      </b-col>
    </b-row>
    <hr class="invoice-spacing">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-goals-view
          :content-id="Number(content.id)"
          :all-goals="allGoals"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
    <hr class="invoice-spacing">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <content-charity-view
          :content-id="Number(content.id)"
          :can-edit-content="canEditContent"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BMedia,
  BMediaAside,
  BLink,
  BImg,
  BAvatar,
  BMediaBody,
  BRow,
  BCol,
  BBadge,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/named
import { useToast } from 'vue-toastification/composition'
import { useClipboard } from '@vueuse/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import constants from '@/constants/static.json'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import {
  mapFrequencyToVariant,
  mapTeamRoleToVariant,
  isContentEditableOrDeletable,
  mapToContentTypeIcon,
  updateBreadcrumb,
} from '@/constants/utils'
import ContentTaskView from './ContentTaskView.vue'
import ContentGoalsView from './ContentGoalsView.vue'
import ContentCharityView from './ContentCharityView.vue'
import ContentSetView from './ContentSetView.vue'
import ContentSetLineChart from './ContentSetLineChart.vue'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BLink,
    BImg,
    ContentSetView,
    ContentSetLineChart,
    ContentTaskView,
    ContentGoalsView,
    ContentCharityView,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      invitationLink: '',
      leaderboardLink: '',
      landingLink: '',
      analyzeData: '',
      canEditContent: false,
      userData: useJwt.getUser() || {},
      content: {
        id: 0,
        externalReference: '',
        sourceContentId: null,
        contentStatusId: 0,
        contentFrequencyId: 0,
        contentTypeId: 0,
        projectId: 0,
        companyId: 0,
        length: 0,
        name: '',
        start: '',
        end: null,
        tag: '',
        language: '',
        chatEnabled: false,
        visibility: false,
        template: false,
        analyzeIndividual: false,
        description: constants.CONTENT_DESCRIPTION,
        contentFrequency: {},
        contentStatus: {},
        contentType: {},
      },
      appUrl: {
        android: constants.APP_URL.ANDROID,
        ios: constants.APP_URL.IOS,
        webGl: constants.APP_URL.WEB_GL,
      },
      contentType: constants.CONTENT_TYPE,
      breadCrumb: constants.BREADCRUMB,
    }
  },
  computed: {
    ...mapState('task', ['allContentTasks']),
    ...mapState('settime', ['allSettimes']),
    ...mapState('goal', ['allGoals']),
    ...mapState('kpi', ['allKpis']),
    ...mapState('team', ['allTeams']),
    ...mapState('goal', ['allGoals']),
    ...mapState('project', ['project']),
  },
  created() {
    if (router.currentRoute.params.id) {
      this.content.id = router.currentRoute.params.id
      this.fetchContent(this.content.id)
    }
  },
  methods: {
    ...mapActions('settime', ['fetchSetReport']),
    ...mapActions('report', ['fetchReportByID']),
    ...mapActions('task', ['fetchContentTaskByID']),
    ...mapActions('settime', ['fetchAllSettimes']),
    ...mapActions('goal', ['fetchAllGoals']),
    ...mapActions('team', [
      'fetchAllTeams']),
    ...mapActions('content', ['updateContent',
      'fetchContentByID']),
    ...mapActions('project', [
      'fetchProjectByID',
    ]),
    async fetchContent(id) {
      const response = await this.fetchContentByID(id)
      if (response && response.data) {
        this.content = response.data
        this.configureBreadcrumbs()
        this.canEditContent = isContentEditableOrDeletable(this.content, this.userData.userRoleId)
        useJwt.setContent(this.content)
        await this.fetchContentTaskByID(this.content.id)
        await this.fetchAllSettimes(this.content.id)
        await this.fetchAllGoals(this.content.id)
        await this.fetchProjectByID(this.content.projectId)
      }
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const project = useJwt.getBreadcrumb(this.breadCrumb.PROJECT)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        { text: project.name, to: `/project/project-view/${project.id}`, active: false },
        { text: this.content.name, active: true },
      ]
      useJwt.setBreadcrumb(this.breadCrumb.CONTENT, { id: this.content.id, name: this.content.name })
      updateBreadcrumb(this, breadcrumbs)
    },
    async fetchAnalyzeByID(id) {
      const payload = {
        id,
        params: '?setid=0&groupid=0',
      }
      this.fetchGroupResultByID(payload).then(response => {
        if (response.data) {
          this.analyzeData = response.data.feedback.replace(/\n/g, '<br>')
        }
      })
    },
    async downloadSetReport() {
      await this.fetchSetReport(`${this.competition.ID}.xlsx`).then(response => {
        if (response) {
          this.successMessage(this.$i18n.t('MESSAGE.REPORT_DOWNLOAD'))
        }
      })
    },
    validateContentPublishReadiness(data) {
      if (this.allContentTasks.length === 0) {
        this.warningMessage(this.$i18n.t('MESSAGE.PUBLISH_NO_TASKS'))
        return
      }

      this.confirmAndPublishContent(data)
    },
    setLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}onboarding-user/${data.encrypt_id}`
      this.leaderboardLink = `${process.env.VUE_APP_HOST}leaderboard/${data.encrypt_id}`
      this.landingLink = `${process.env.VUE_APP_HOST}${data.encrypt_id}`
    },
    confirmAndPublishContent(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.PUBLISH_CONFIRMATION')} ${data.name}  content ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.contentStatusId = 2
            this.updateContent(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.CONTENT_PUBLISHED'))
                this.fetchContent(this.content.id)
              }
            })
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyLink = link => {
      copy(link)
      toast({
        component: ToastificationContent,
        props: {
          title: 'The link is copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    const {
      successMessage,
      warningMessage,
    } = mixinAlert()
    return {
      copyLink,
      mapFrequencyToVariant,
      mapTeamRoleToVariant,
      mapToContentTypeIcon,
      updateBreadcrumb,
      successMessage,
      warningMessage,
    }
  },

}
</script>
