<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Content Results and Insights (⚠️ Design only, using dummy data)
        </b-card-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="line"
        height="400"
        :options="apexChatData.lineChartSimple.chartOptions"
        :series="apexChatData.lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
  },
  data() {
    return {
      apexChatData: {
        lineChartSimple: {
          chartOptions: {
            chart: {
              type: 'line',
              height: 400,
              toolbar: {
                show: false,
              },
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              categories: ['Set 1', 'Set 2', 'Set 3', 'Set 4', 'Set 5', 'Set 6', 'Set 7', 'Set 8'],
            },
            markers: {
              size: 5,
            },
            colors: ['#7367F0', '#28C76F', '#EA5455'],
            dataLabels: {
              enabled: false,
            },
            grid: {
              borderColor: '#e7e7e7',
            },
          },
          series: [
            {
              name: 'Average',
              data: [2, 4, 5, 3, 4, 2, 3, 5],
            },
            {
              name: 'Min Answer',
              data: [2, 4, 5, 2, 3, 1, 2, 4],
            },
            {
              name: 'Max Answer',
              data: [2, 4, 5, 4, 5, 3, 5, 6],
            },
          ],
        },
      },
    }
  },
}
</script>
