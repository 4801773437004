<template>
  <b-card
    no-body
  >
    <!-- Sets/Milestones -->
    <div class="d-flex">
      <h4 class="mb-2 ml-2">
        {{ $t('SETS_MILESTONES') }}
      </h4>
      <div>
        <feather-icon
          v-if="canEditContent"
          :icon="settimes.length === 0 ? 'PlusCircleIcon' : 'EditIcon'"
          size="16"
          class="text-muted cursor-pointer ml-1 mr-1"
          @click="$router.push({ name: 'set-list-id', params: { id: contentId }})"
        />
      </div>
    </div>
    <!-- No Set Message -->
    <div
      v-if="settimes.length === 0"
      class="text-center mt-1"
    >
      <p class="text-muted">
        {{ $t('MESSAGE.NO_SET_AVAILABLE') }}
      </p>
    </div>
    <app-timeline class="mb-2 ml-3">
      <!-- Sets-->
      <app-timeline-item
        v-for="(settime,index) in settimes"
        :id="settime.id.toString()"
        :key="index"
        variant="primary"
        icon="ClockIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>
            {{ $t("SET") }} {{ index+1 }} : {{ formatDateTime(settime.start) }} - {{ formatDateTime(settime.end) }} - {{ $t("TOTAL_RESPONSE") }} {{ settime.attemptedUserCount }}
          </h6>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
  },
  mixins: [mixinDate],
  props: {
    settimes: {
      type: Array,
      default: () => [],
      required: true,
    },
    canEditContent: {
      type: Boolean,
      default: () => true,
    },
    contentId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
}
</script>
