<template>
  <b-card no-body>
    <!-- Charity Section -->
    <div class="d-flex">
      <h4 class="mb-2 mr-1 ml-1">
        {{ $t('Charity') }}
      </h4>
      <feather-icon
        v-if="canEditContent"
        :icon="charities.length === 0 ? 'PlusCircleIcon' : 'EditIcon'"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'charity-list-id', params: { id: contentId }})"
      />
    </div>
    <!-- No Charities Message -->
    <div
      v-if="charities.length === 0"
      class="text-center mt-1"
    >
      <p class="text-muted">
        {{ $t('MESSAGE.NO_CHARITIES_FOUND') }}
      </p>
    </div>
    <div v-if="charities.length > 0">
      <section id="feather-icons">
        <div
          id="icons-container"
          class="d-flex flex-wrap"
        >
          <b-card
            v-for="charity in charities"
            :key="charity.id"
            v-b-tooltip.hover.top="charity.name"
            class="icon-card cursor-pointer text-center mb-2 mx-50"
          >
            <div class="icon-wrapper">

              <b-img
                :src="charity.media.url"
                alt="avatar img"
                width="90px"
                height="90px"
              />
            </div>
          </b-card>
        </div>
      </section>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BImg,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    canEditContent: {
      type: Boolean,
      default: () => true,
    },
    contentId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      charities: [],
    }
  },
  setup() {
    const {
      errorMessage,
    } = mixinAlert()

    return {
      errorMessage,
    }
  },
  computed: {
    ...mapState('charity', ['allCharities', 'allContentCharities']),
  },
  async created() {
    try {
      await this.fetchAllCharities()
      await this.fetchAllContentCharities(this.contentId)
      this.charities = this.mapContentCharity()
    } catch (error) {
      this.errorMessage(this.$i18n.t('MESSAGE.PAGE_LOAD_FAILED'))
    }
  },
  methods: {
    ...mapActions('charity', [
      'fetchAllCharities',
      'fetchAllContentCharities']),
    mapContentCharity() {
      try {
        const charities = JSON.parse(JSON.stringify(this.allCharities))
        const matchingCharities = this.allContentCharities
          .map(e => charities.find(element => element.id === e.charityId))
        return matchingCharities
      } catch (error) {
        this.showErrorMessage()
        return []
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
